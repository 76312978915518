<template>
  <div>
    <vx-card class="mt-5 mb-5" v-if="stories.stories">
      <h2>{{ childName }}'s Story</h2>
      <div class="child-story-steps">
        <ul>
          <li v-for="(story,index) in stories.stories" :key="story._id" :class="stepClass(index)"><span>{{
              index + 1
            }}</span></li>
        </ul>
      </div>

      <!--Update form-->
      <div v-for="(story,index) in stories.stories" :key="story._id" v-if="!isPreview">
        <div v-show="index == currentIndex">
          <span class="story-instruction">{{ index + 1 + '. ' + story.instruction }}</span>
          <div  v-if="story.type == 'text'" class="mt-5">
            <vs-textarea counter="600" v-model="answers[index].story"
                         class="mt-2"/>
          </div>

          <div v-else>
            <div class="w-full mb-5 story-img story-step">
              <template v-if="answers[index].dataImg">
                <vs-avatar :src="answers[index].dataImg" class="add-room-image"/>
                <div class="modify-img flex my-5">
                  <input
                    type="file"
                    class="hidden"
                    :ref="'uploadImgInput' + story._id"
                    @change="updateCurrImg($event,index)"
                    accept="image/*"
                  />
                  <vs-button href="#" @click="displayRef('uploadImgInput' + story._id)">Update Image</vs-button>
                  <vs-button
                    color="danger"
                    class="ml-4"
                    @click="answers[index].dataImg = null; answers[index].story = null"
                  >Remove Image
                  </vs-button>
                </div>
              </template>
              <div class="upload-img mt-5" v-if="!answers[index].dataImg">
                <input
                  type="file"
                  class="hidden"
                  :ref="'uploadImgInput' + story._id"
                  @change="updateCurrImg($event,index)"
                  accept="image/*"
                />
                <vs-button type="border" class="btn-blue-border" @click="displayRef('uploadImgInput' + story._id)">Upload Photo</vs-button>
              </div>
            </div>
          </div>
          <span v-if="error" style="color: red">{{ error }}</span>
        </div>
      </div>

      <!--Preview-->
      <div v-if="isPreview" class="mt-5">
        <h4 class="mb-2 text-black"><strong>{{ stories.stories.length }} . Review your child's story</strong>
        </h4>
        <div class="">
          <div v-for="(story,index) in stories.stories" :key="story._id" v-if="isPreview">
            <h4 class="mb-2 text-black"><strong>{{ index + 1 + '. ' + story.instruction }}</strong></h4>
            <p v-if="story.type == 'text'" class="mb-2"> {{ answers[index].story }}</p>
            <p v-else class="story-img"><img :src="answers[index].dataImg"></p>
          </div>
        </div>
      </div>

      <div class="flex" style="margin-top: 45px">
        <vs-button color="primary" type="filled" @click="previous" v-show="currentIndex != 0">
          Previous
        </vs-button>
        <vs-button color="primary" type="filled" @click="next" v-show="!isPreview" class="ml-auto">
          Next
        </vs-button>
        <vs-button color="primary" type="filled" @click="saveStory" v-show="isPreview" class="ml-auto">
          Submit
        </vs-button>
        <vs-button color="primary" type="filled" @click="$router.push({ name: 'contributor-child-story', params: { id: $route.params.id } })" class="ml-auto">
          Cancel
        </vs-button>
      </div>

    </vx-card>

  </div>
</template>

<script>
import {mapActions} from "vuex";
import _ from "lodash";

export default {
  data() {
    return {
      contributor: {},
      stories: [],
      answers: [],
      dataImg: "",
      isPreview: false,

      error: null,
      childName: null,
      currentIndex: 0

    };
  },
  methods: {
    ...mapActions("parent", [
      "getChildStoryForContributor",
      "updateChildStoryForContributor",
      "getContributorDetail",
    ]),
    ...mapActions("childrenStories", [
      "getChildStoryById",
    ]),
    stepClass(index) {
      if (index < this.currentIndex) {
        return "step-complete";
      } else if (index == this.currentIndex) {
        return "step-complete last";
      } else {
        return "";
      }
    },
    previous() {
      if (this.isPreview) {
        this.error = null;
        this.isPreview = false;
      } else {
        this.error = null;
        this.currentIndex--;
      }

    },
    next() {
      this.error = null;

      if (this.currentIndex != (this.stories.stories.length - 1)) {
        if (
          (this.answers[this.currentIndex].story != null
            && this.answers[this.currentIndex].story != "") ||
          (this.answers[this.currentIndex].dataImg != null
            && this.answers[this.currentIndex].dataImg != "")
        ) {
          this.currentIndex++;
        } else {
          this.error = "Please fill the field.";
        }
      } else {
        this.isPreview = true;
      }
    },
    displayRef(ref) {
      this.$refs[ref][0].click()
    },
    saveStory() {

      this.error = null;
      if (
        (this.answers[this.currentIndex].story != null
          && this.answers[this.currentIndex].story != "") ||
        (this.answers[this.currentIndex].dataImg != null
          && this.answers[this.currentIndex].dataImg != "")
      ) {
        let data = new FormData();
        let self = this;
        data.append(`storyId`, this.contributor.storyId);

        _.forEach(self.answers, function (value, key) {
          data.append(`presetId[${key}]`, value.presetId);
          data.append(`order[${key}]`, value.order);
          data.append(`type[${key}]`, value.type);
          data.append(`instruction[${key}]`, value.instruction);
          data.append(`story[${key}]`, value.story);
        });

        this.updateChildStoryForContributor(data)
          .then((res) => {
            this.$router.push({ name: 'contributor-child-story', params: { id: this.$route.params.id } })
            this.$vs.notify({
              title: 'Success',
              text: "Story updated successfully",
              iconPack: 'feather',
              icon: 'icon-alert-circle',
              color: 'success'
            })
          })
          .catch((err) => {
            this.$vs.notify({
              title: 'Failure',
              text: "Something went wrong",
              iconPack: 'feather',
              icon: 'icon-alert-circle',
              color: 'danger'
            })
          });
      } else {
        this.error = "Please fill the field.";
      }

    },

    async updateCurrImg(input, index) {
      if (input.target.files && input.target.files[0]) {
        this.answers[index].story = input.target.files[0];
        var reader = new FileReader();
        reader.onload = (e) => {
          this.answers[index].dataImg = e.target.result;
        };
        reader.readAsDataURL(input.target.files[0]);
      }
    },
  },
  async created() {

  },
  async mounted() {

    await this.getContributorDetail(this.$route.params.id)
      .then(async (res) => {
        this.contributor = res.data.data;
        this.childName = this.contributor.childId.fullName;
        this.$emit('setContributorLearningCenter', this.contributor.learningCenterId);
      })

    await this.getChildStoryById(this.contributor.storyId)
      .then(async (res) => {

        let self = this;
        self.stories = res.data.data;
        await _.forEach(self.stories.stories, function (value, key) {
          const obj = {
            presetId: value.presetId,
            order: value.order,
            type: value.type,
            instruction: value.instruction,
            story: value.story,
            dataImg: value.story
          }
          self.answers.push(obj);
        });
      });
  }

};
</script>
<style scoped lang="scss">
.add-room-image {
  width: 123px;
  height: 123px;
  margin: 0;
  margin-top: 20px;
}
</style>
